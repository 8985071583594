<template>
  <b-modal
    :id="id"
    :ref="id"
    :hide-header-close="!dismissable"
    :no-close-on-esc="!dismissable"
    :no-close-on-backdrop="!dismissable"
    :scrollable="scrollable"
    :size="size"
    :title="titleComputed"
    visible
    @hidden="remove"
  >
    <component
      :is="component"
      v-if="component"
    />
    <form-wrapper
      v-else-if="showInput"
      :id="formId"
      :form-model="formModelComputed"
      :prune-unentered="false"
      :show-submit="false"
      @error="isSubmitting = false"
      @submit="resolve"
    />
    <p
      v-for="(paragraph, ix) of messageComputed"
      v-else
      :key="ix"
      v-text="paragraph"
    />
    <template v-slot:modal-footer>
      <b-btn
        v-if="showCancel"
        :disabled="isSubmitting"
        @click="action(false)"
      >
        {{ cancelText }}
      </b-btn>
      <b-btn
        :disabled="isSubmitting"
        @click="action(true)"
      >
        {{ okText }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import FormWrapper from './form/form-wrapper.vue';

export default {
  name: 'Prompt',
  components: { FormWrapper },
  props: {
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    canCancel: {
      type: Boolean,
      default: null,
    },
    component: {
      type: [Object, Function],
      default: null,
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    eventHandlers: {
      type: Array,
      default: () => ([]),
    },
    eventKey: {
      type: String,
      required: true,
    },
    formModel: {
      type: Object,
      default: null,
    },
    hasInput: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    message: {
      type: [Array, String],
      default: null,
    },
    okText: {
      type: String,
      default: 'Ok',
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    title: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    hasResolved: false,
    isSubmitting: false,
  }),
  computed: {
    messageComputed() {
      if (!this.message) return [this.titleComputed];
      if (Array.isArray(this.message)) return this.message;
      return [this.message];
    },
    titleComputed() {
      if (this.title) return this.title;
      if (this.dismissable) return 'Message';
      if (this.showInput) return 'Input';
      if (this.canCancel) return 'Confirm';
      return 'Alert';
    },
    formId() {
      return this.showInput ? `${this.id}-form` : null;
    },
    formModelComputed() {
      if (this.showInput) {
        if (this.formModel) return this.formModel;
        return {
          fields: [{ label: '', id: 'input', display_type: 'text' }],
        };
      }
      return null;
    },
    showCancel() {
      return this.canCancel === null ? this.showInput : this.canCancel;
    },
    showInput() {
      return this.hasInput || this.formModel;
    },
  },
  mounted() {
    const ref = this.$refs[this.id];
    this.eventHandlers.forEach((eh) => {
      ref.$on(eh.event, (e) => eh.handler(e, this));
    });
  },
  methods: {
    action(confirm = true) {
      this.isSubmitting = true;
      if (this.showInput && confirm) this[`$${this.$cvePrefix}Form`].submit(this.formId);
      else this.resolve(confirm);
    },
    remove() {
      if (!this.hasResolved) this.$root.$emit(this.eventKey, null);
      this[`$${this.$cvePrefix}Prompt`].remove(this.id);
    },
    resolve(result) {
      this.$root.$emit(this.eventKey, result);
      this.hasResolved = true;
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
