<template>
  <b-form-radio-group
    :id="id"
    v-model="mutableValue"
    :class="`${fieldClass}--radio`"
    :disabled="disabled"
    :options="options"
    :state="state"
  />
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { deepGet } from '../../../../utils/object';
import { parseObject } from '../../../../utils/parsers';

export default {
  name: 'RadioField',
  mixins: [fieldMixin],
  computed: {
    options() {
      const options = parseObject(deepGet(this, 'field.options'));
      let radioOptions = [];
      if (Array.isArray(options)) {
        radioOptions = options.map((option) => {
          if (typeof option === 'object') return option;
          return { value: option, text: option };
        });
      } else {
        radioOptions = Object.keys(options).map((key) => ({ value: key, text: options[key] }));
      }
      return radioOptions;
    },
  },
};
</script>
