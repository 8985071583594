<template>
  <b-form-checkbox-group
    :id="id"
    v-model="mutableValue"
    :class="`${fieldClass}--text`"
    :disabled="disabled"
    :state="state"
    :options="options"
  />
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { deepGet } from '../../../../utils/object';
import { parseArray, parseObject } from '../../../../utils/parsers';

export default {
  name: 'CheckboxGroupField',
  mixins: [fieldMixin],
  computed: {
    options() {
      const options = parseObject(deepGet(this, 'field.options'));
      if (Array.isArray(options)) return options;
      return Object.keys(options).map((key) => ({ value: key, text: options[key] }));
    },
  },
  methods: {
    parseInitialValue(val) {
      return parseArray(val);
    },
  },
};
</script>
