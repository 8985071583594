<template>
  <b-form-input
    :id="id"
    v-model="mutableValue"
    :class="`${fieldClass}--tel`"
    :disabled="disabled"
    :state="state"
    trim
    type="tel"
  />
</template>

<script>
import fieldMixin from '../../../../mixins/field';

export default {
  name: 'TelField',
  mixins: [fieldMixin],
};
</script>
