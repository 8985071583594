<template>
  <b-input-group :class="`${fieldClass}--currency`">
    <template v-slot:prepend>
      <b-input-group-text v-text="symbol" />
    </template>
    <b-form-input
      :id="id"
      v-model="mutableValue"
      :disabled="disabled"
      min="0"
      :state="state"
      step="0.01"
      type="number"
    />
  </b-input-group>
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { parseNumber } from '../../../../utils/parsers';

export default {
  name: 'CurrencyField',
  mixins: [fieldMixin],
  props: {
    symbol: {
      type: String,
      default: '£',
    },
  },
  methods: {
    async delaySetMutableValue(value) {
      await this.$nextTick();
      this.mutableValue = value;
    },
    parseInitialValue(value) {
      return this.transformValue(value);
    },
    transformMutableValue(mutableValue) {
      if (!mutableValue) return null;
      try {
        // Extra decimal places won't trigger an update if they round to the same value
        // This makes sure they get trimmed
        const match = mutableValue.match(/^(.*\..{2}).+/);
        if (match) {
          const [, trimmed] = match;
          this.delaySetMutableValue(trimmed);
        }
      } catch { /**/ }
      return parseNumber(parseNumber(mutableValue, { defaultReturn: 0 }) * 100, { asInt: true });
    },
    transformValue(value) {
      if (!value) return null;
      return parseNumber(value, { defaultReturn: 0 }) / 100;
    },
  },
};
</script>
