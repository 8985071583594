<template>
  <component
    :is="tag"
    v-html="mdHtml /* eslint-disable-line vue/no-v-html */"
  />
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';

export default {
  props: {
    markdown: {
      type: String,
      default: '',
    },
    renderer: {
      type: Object,
      default: () => new marked.Renderer(),
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  data: () => ({
    mdHtml: null,
  }),
  watch: {
    markdown: {
      immediate: true,
      handler(markdown) {
        this.mdHtml = this.markdownToHtml(markdown);
      },
    },
  },
  methods: {
    markdownToHtml(markdown) {
      const { renderer } = this;
      marked.setOptions({ renderer });
      DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        if ('target' in node) {
          node.setAttribute('target', '_blank');
          node.setAttribute('rel', 'noopener noreferrer');
        }
      });
      return DOMPurify.sanitize(marked(markdown));
    },
  },
};
</script>
