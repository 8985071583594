<template>
  <div
    :id="id"
    :class="`${fieldClass}--yes-no`"
  >
    <b-btn
      class="mr-2"
      :disabled="disabled"
      :variant="mutableValue ? variant : `outline-${variant}`"
      @click="mutableValue = true"
      v-text="'Yes'"
    />
    <b-btn
      :disabled="disabled"
      :variant="mutableValue === false ? variant : `outline-${variant}`"
      @click="mutableValue = false"
      v-text="'No'"
    />
  </div>
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { parseBoolean } from '../../../../utils/parsers';

export default {
  name: 'YesNoField',
  mixins: [fieldMixin],
  props: {
    buttonVariant: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    variant() {
      if (this.isValidated) return this.state ? 'success' : 'danger';
      return this.buttonVariant;
    },
  },
  methods: {
    parseInitialValue(value) {
      return parseBoolean(value, { defaultReturn: null });
    },
  },
};
</script>
