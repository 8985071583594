<template>
  <v-select
    :id="id"
    v-model="mutableValue"
    :class="[
      `${fieldClass}--select-many`,
      isValidated ? `border rounded border-${borderVariant}` : null
    ]"
    :disabled="disabled"
    multiple
    :options="options"
    :reduce="option => option.value"
  >
    <template v-slot:no-options>
      No options found.
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';
import fieldMixin from '../../../../mixins/field';
import { deepGet } from '../../../../utils/object';
import { parseArray, parseObject } from '../../../../utils/parsers';

export default {
  name: 'SelectManyField',
  components: { vSelect },
  mixins: [fieldMixin],
  computed: {
    borderVariant() {
      return this.state ? 'success' : 'danger';
    },
    options() {
      const options = parseObject(deepGet(this, 'field.options'));
      if (Array.isArray(options)) return options.map((opt) => ({ value: opt, label: opt }));
      return Object.keys(options).map((key) => ({ value: key, label: options[key] }));
    },
  },
  methods: {
    parseInitialValue(val) {
      // Don't know what an initial value will look like yet so this will probably need to change
      return parseArray(val);
    },
  },
};
</script>
