<template>
  <div
    :id="id"
    :class="[
      `${fieldClass}--repeatable`,
      isValidated ? `border border-${borderVariant}` : null
    ]"
  >
    <b-table
      :busy="disabled"
      class="mb-0"
      :fields="tableFields"
      :items="mutableValue"
      outlined
      show-empty
      small
      striped
    >
      <template v-slot:empty>
        <i>No items added</i>
      </template>
      <template v-slot:cell(actions)="cell">
        <b-btn
          v-for="action of [
            { key: 'edit', label: 'Edit', icon: 'faPencilAlt' },
            { key: 'remove', label: 'Delete', icon: 'farTrashAlt' },
          ]"
          :key="action.key"
          v-b-tooltip.hover="action.label"
          class="mx-1"
          :disabled="disabled"
          size="sm"
          @click="doAction(action.key, cell)"
        >
          <font-awesome-icon :icon="icons[action.icon]" />
        </b-btn>
      </template>
      <template v-slot:bottom-row="{columns}">
        <b-td :colspan="columns">
          <b-btn
            :disabled="disabled"
            size="sm"
            @click="add"
          >
            <font-awesome-icon :icon="icons.faPlusCircle" /> Add item
          </b-btn>
        </b-td>
      </template>
    </b-table>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTrashAlt as farTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import fieldMixin from '../../../../mixins/field';
import { parseArray } from '../../../../utils/parsers';

export default {
  name: 'RepeatableField',
  components: { FontAwesomeIcon },
  mixins: [fieldMixin],
  data: () => ({
    icons: {
      faPencilAlt,
      faPlusCircle,
      farTrashAlt,
    },
  }),
  computed: {
    borderVariant() {
      return this.state ? 'success' : 'danger';
    },
    tableFields() {
      if (this.mutableValue.length === 0) return ['Items'];
      return this.field.form_model.fields
        .map((field) => ({ label: field.label, key: field.id.toString() }))
        .concat([{ label: 'Actions', key: 'actions' }]);
    },
  },
  methods: {
    doAction(action, cell) {
      this[action](cell);
    },
    async add() {
      const res = await this[`$${this.$cvePrefix}Prompt`].prompt({
        title: 'Add item',
        formModel: this.getFormModel(),
      });
      if (res) this.mutableValue.push(res);
    },
    async edit(cell) {
      const { index } = cell;
      const formModel = this.getFormModel();
      formModel.fields.forEach((field) => {
        const value = this.mutableValue[index][field.id];
        if (typeof value !== 'undefined') field.value = value;
      });
      const res = await this[`$${this.$cvePrefix}Prompt`].prompt({
        title: `Edit item ${index + 1}`,
        formModel,
      });
      if (res) this.mutableValue.splice(index, 1, res);
    },
    async remove(cell) {
      const { index } = cell;
      const res = await this[`$${this.$cvePrefix}Prompt`].prompt({
        message: `Are you sure you want to delete item ${index + 1}?`,
        canCancel: true,
      });
      if (res) this.mutableValue.splice(index, 1);
    },
    getFormModel() {
      return cloneDeep(this.field.form_model);
    },
    parseInitialValue(val) {
      // Don't know what an initial value will look like yet so this will probably need to change
      return parseArray(val);
    },
  },
};
</script>
