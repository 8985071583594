<template>
  <dialog v-if="prompts.length > 0">
    <Prompt
      v-for="prompt of prompts"
      :key="prompt.id"
      v-bind="prompt"
    />
  </dialog>
</template>

<script>
import { mapState } from 'vuex';
import Prompt from './prompt.vue';

export default {
  name: 'PromptContainer',
  components: { Prompt },
  computed: {
    ...mapState({
      prompts(state, getters) {
        return getters[`${this.$cvePrefix}Prompt/prompts`];
      },
    }),
  },
};
</script>
