<template>
  <b-form-checkbox
    :id="id"
    v-model="mutableValue"
    :class="`${fieldClass}--checkbox mt-2`"
    :disabled="disabled"
    inline
    :state="state"
  >
    <template v-slot>
      <form-label
        :field="field"
        tag="div"
      />
    </template>
  </b-form-checkbox>
</template>

<script>
import { parseBoolean } from '../../../../utils/parsers';
import fieldMixin from '../../../../mixins/field';
import FormLabel from '../form-label.vue';

export default {
  name: 'CheckboxField',
  components: { FormLabel },
  mixins: [fieldMixin],
  methods: {
    parseInitialValue(value) {
      return parseBoolean(value, { defaultReturn: null });
    },
  },
};
</script>
