<template>
  <b-form
    :id="id"
    :class="[
      `${$cvePrefix}-form`,
      {accordion: isAccordion},
    ]"
    @submit.prevent.stop="onSubmit"
  >
    <component
      :is="isAccordion ? 'b-card' : 'div'"
      v-for="(section, sectionIndex) of form.sections"
      :key="`${id}-section-${section.id}`"
      :class="[`${$cvePrefix}-form__section`, classes.section]"
      v-bind="getSectionProps(section)"
    >
      <b-card-header
        v-if="showSectionComplete(sectionIndex)"
        :class="[
          'bg-transparent',
          `${$cvePrefix}-form__section--complete__message`
        ]"
      >
        <component :is="completedSectionComponent" />
      </b-card-header>
      <component
        :is="isAccordion ? 'b-collapse' : 'div'"
        :id="`${id}-section-${sectionIndex}`"
        :accordion="isAccordion ? `${id}-accordion` : null"
        :class="[{'p-3': isAccordion && !classes.sectionBody}, classes.sectionBody]"
        @hidden="$root.$emit('bv::hidden::collapse', `${id}-section-${sectionIndex}`)"
        @shown="$root.$emit('bv::shown::collapse', `${id}-section-${sectionIndex}`)"
      >
        <b-row
          v-for="(group, groupIndex) of section.groups"
          :key="`${id}-group-${group.id}`"
          :class="[
            `${$cvePrefix}-form__group`,
            { 'mb-4': (groupIndex < section.groups.length - 1) && !classes.group },
            classes.group,
          ]"
        >
          <b-col cols="12">
            <markdown
              v-if="group.label && ((section.groups.length > 1) || (group.id !== 'default'))"
              tag="h5"
              :markdown="group.label"
            />
            <b-row>
              <b-col
                v-for="field of group.fields"
                v-show="field.show"
                :key="`${id}-field-${field.id}`"
                :class="[`${$cvePrefix}-form__field`, classes.field]"
                :md="field.columns
                  || (field.display_attributes && field.display_attributes.columns)
                  || '12'"
              >
                <b-row>
                  <b-col cols="12">
                    <form-label
                      v-if="typeof field.label === 'string'
                        && field.label.length > 0
                        && field.display_type !== 'checkbox'"
                      :field="field"
                      :label-for="`${id}-field-${field.id}`"
                    />
                  </b-col>
                  <b-col>
                    <component
                      :is="`${$cvePrefix}-${field.display_type}-field`"
                      v-if="field.display_type"
                      :id="`${id}-field-${field.id}`"
                      v-bind="field.display_attributes"
                      :disabled="field.disabled || isDisabled"
                      :field="field"
                      :state="field.rules && field.rules.length > 0 ? field.state : null"
                      :value="$v.formData[field.id].$model"
                      @input="(value, meta) => onInput(field, value, meta)"
                    />
                  </b-col>
                  <b-col
                    v-if="field.actionable"
                    class="pl-0"
                    cols="auto"
                  >
                    <b-btn
                      :disabled="field.submitting
                        || formData[field.id] === null
                        || field.state === false
                        || field.disabled
                        || isDisabled"
                      :variant="field.action_button_variant || 'primary'"
                      @click="onFieldAction(field)"
                    >
                      <b-spinner
                        v-if="field.submitting"
                        small
                      />
                      {{ field.action_button_text || 'Submit' }}
                    </b-btn>
                  </b-col>
                  <b-col cols="12">
                    <b-form-invalid-feedback
                      :state="field.state"
                      v-text="field.errorMessage"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
          :class="{
            'mt-4': sectionIndex > 0 || sectionIndex < (form.sections.length - 1) || !!showSubmit
          }"
        >
          <b-btn
            v-if="sectionIndex > 0"
            class="mr-2"
            :variant="btnVariants.previous"
            :disabled="isSubmitting"
            @click="changeSection(sectionIndex, -1)"
          >
            Back
          </b-btn>
          <b-btn
            v-if="sectionIndex < (form.sections.length - 1)"
            :variant="btnVariants.next"
            :disabled="isSubmitting"
            @click="changeSection(sectionIndex, 1)"
          >
            Next
          </b-btn>
          <b-btn
            v-else
            v-show="showSubmit"
            :disabled="isDisabled"
            :variant="btnVariants.submit"
            type="submit"
          >
            <b-spinner
              v-if="isSubmitting"
              small
            />
            {{ submitButtonText }}
          </b-btn>
        </div>
      </component>
    </component>
  </b-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { isInViewport, scrollToElement } from '../../../utils/dom';
import { deepGet } from '../../../utils/object';
import FormLabel from './form-label.vue';
import Markdown from './markdown.vue';

export default {
  name: 'FormWrapper',
  components: {
    FormLabel,
    Markdown,
  },
  mixins: [validationMixin],
  props: {
    actions: {
      type: Object,
      default: () => ({}),
    },
    buttonVariants: {
      type: Object,
      default: () => ({}),
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
    changeHandlers: {
      type: Object,
      default: () => ({}),
    },
    completedSectionComponent: {
      type: Object,
      default: null,
      validator: (component) => typeof component.render === 'function',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldErrors: {
      type: Object,
      default: null,
    },
    formModel: {
      type: Object,
      required: true,
      validator: (model) => Array.isArray(model.fields)
        && (!model.groups || Array.isArray(model.groups))
        && (!model.sections || Array.isArray(model.sections)),
    },
    id: {
      type: String,
      default() {
        return `${this.$cvePrefix}-form-${this._uid}`; // eslint-disable-line no-underscore-dangle
      },
    },
    pruneUnentered: {
      type: Boolean,
      default: true,
    },
    scrollToPaddingTop: {
      type: Number,
      default: 0,
    },
    scrollToFieldPaddingTop: {
      type: Number,
      default: 10,
    },
    scrollToOpenSection: {
      type: Boolean,
      default: true,
    },
    scrollToSectionPaddingTop: {
      type: Number,
      default: 32,
    },
    sectionProps: {
      type: Object,
      default: () => ({}),
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    showSubmitting: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: 'Submit',
    },
    validationState: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    currentSectionIndex: -1,
    fieldErrorsMutable: null,
    fields: [],
    firstSectionOpened: false,
    form: { sections: [] },
    formData: {},
    submitting: false,
  }),
  computed: {
    btnVariants() {
      const defaults = {
        next: 'primary',
        previous: 'primary',
        submit: 'primary',
      };
      return { ...defaults, ...this.buttonVariants };
    },
    isAccordion() {
      return this.form.sections.length > 1;
    },
    isDisabled() {
      return this.disabled || this.isSubmitting;
    },
    isSubmitting() {
      return this.submitting || this.showSubmitting;
    },
    rootEventPrefix() {
      return `${this.$cvePrefix}-form-${this.id}`;
    },
  },
  watch: {
    fieldErrors: {
      immediate: true,
      deep: true,
      handler(errors) {
        this.fieldErrorsMutable = errors;
      },
    },
    fieldErrorsMutable(errors, previousErrors) {
      if (errors || previousErrors) {
        Object.keys(errors || previousErrors).forEach((errorField) => {
          this.validateState(this.fields.find((f) => [f.id, f.key].includes(errorField)));
        });
      }
    },
    formModel: {
      immediate: true,
      async handler(model) {
        const categorize = (items = [], options = {
          catItemsKey: 'fields',
          itemCatIdKey: 'group_id',
          modelCatKey: 'groups',
          parentCatIdKey: 'section_id',
        }) => {
          const { modelCatKey } = options;
          const categories = items.reduce((arr, item) => {
            // Sort items in to arrays on category objects using item category IDs
            const { catItemsKey, itemCatIdKey, parentCatIdKey } = options;
            const catId = item[itemCatIdKey] || 'default';
            const cat = (model[modelCatKey] || []).find((c) => c.id === catId)
              || { id: 'default', label: 'Other' };
            if (parentCatIdKey && !cat[parentCatIdKey]) cat[parentCatIdKey] = 'default';
            let pushedCat = arr.find((c) => c.id === catId);
            if (!pushedCat) {
              arr.push(cat);
              pushedCat = arr[arr.length - 1];
            }
            if (!Array.isArray(pushedCat[catItemsKey])) pushedCat[catItemsKey] = [];
            pushedCat[catItemsKey].push(item);
            return arr;
          }, []);
          if (Array.isArray(model[modelCatKey])) {
            // Sort categories in order of form model array
            const orderedCats = [];
            const catIds = model[modelCatKey].map((c) => c.id).concat('default');
            catIds.forEach((id) => {
              const cat = categories.find((g) => g.id === id);
              if (cat) orderedCats.push(cat);
            });
            return orderedCats;
          }
          return categories;
        };

        this.fields = [];
        this.formData = {};
        model.fields.forEach((field) => {
          if (typeof field.parent_id !== 'undefined' && field.parent_id !== null) {
            const parent = model.fields.find((f) => f.id === field.parent_id);
            if (parent) field.parent = parent;
          }
          if (typeof field.start_hidden === 'boolean') field.show = !field.start_hidden;
          if (typeof field.start_hidden === 'function') field.show = !field.start_hidden(field, this.formModel);
          if (Array.isArray(field.rules)) {
            field.rules.forEach((ruleString) => {
              const match = ruleString.match(/^(?<rule>[^:]+_f):(?<fieldKey>[^:,]+)/); // any rule ending in _f
              if (match) {
                const comparedField = model.fields.find((f) => f.key === match.groups.fieldKey);
                if (comparedField) {
                  if (!Array.isArray(comparedField.dependants)) comparedField.dependants = [];
                  comparedField.dependants.push(field);
                }
              }
            });
          }
          this.$set(
            this.formData,
            field.id,
            typeof field.value !== 'undefined' ? field.value : null,
          );
        });
        const groupedFields = categorize(model.fields);
        this.form.sections = categorize(groupedFields, {
          catItemsKey: 'groups',
          itemCatIdKey: 'section_id',
          modelCatKey: 'sections',
        });
        this.fields = this.form.sections.reduce((sectionArr, section) => ([
          ...sectionArr,
          ...section.groups.reduce((groupArr, group) => ([...groupArr, ...group.fields]), []),
        ]), []);
        await this.$nextTick();
        if (this.currentSectionIndex !== 0) this.changeSection(0, 0);
      },
    },
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        this.$emit('data-change', data);
        this.fields.forEach(this.checkConstraints);
      },
    },
    '$v.$anyError': {
      immediate: true,
      handler(bool) {
        this.$emit('update:validationState', !bool);
      },
    },
  },
  mounted() {
    this.$root.$on(`${this.rootEventPrefix}-doSubmit`, this.onSubmit);
    if (this.scrollToOpenSection) {
      this.$root.$on('bv::shown::collapse', this.autoScrollAccordion);
    }
  },
  beforeDestroy() {
    this.$root.$off(`${this.rootEventPrefix}-doSubmit`);
    this.$root.$off('bv::shown::collapse');
  },
  methods: {
    autoScrollAccordion(section) {
      // don't scroll when the first section is initially opened as the form may not be in view
      if (this.firstSectionOpened) {
        this.scrollToSection(section);
      } else {
        this.firstSectionOpened = true;
      }
    },
    changeSection(currentIndex, indexChange = 1) {
      if (indexChange > 0) {
        const currentFields = this.getFieldsFromSection(this.form.sections[currentIndex]);
        if (!this.validateFields(currentFields)) return;
      }
      const newIndex = currentIndex + indexChange;
      if (this.isAccordion) {
        this.$root.$emit('bv::toggle::collapse', `${this.id}-section-${newIndex}`);
      }
      this.currentSectionIndex = newIndex;
    },
    checkConstraints(field) {
      let show = typeof field.show === 'boolean' ? field.show : true;
      if (field.parent) {
        show = !!field.parent.show;
        if (show && field.parent_constraint) {
          const validator = this[`$${this.$cvePrefix}Validations`]
            .getValidation(field.parent_constraint, field, this.formData, this.fields);
          show = validator(this.formData[field.parent.id]);
        }
      }
      this.$set(field, 'show', show);
    },
    getErrorMessage(field) {
      const vuelidator = this.$v.formData[field.id];
      const validatorKeys = Object.keys(vuelidator).filter((k) => k.charAt(0) !== '$');
      const errorKeys = validatorKeys.filter((k) => vuelidator[k] === false);
      return errorKeys.reduce((str, key, ix) => {
        const ruleString = field.rules.find((v) => v.split(':')[0] === key);
        if (ruleString) {
          str += this[`$${this.$cvePrefix}Validations`].getMessage(ruleString, field, this.formData, this.fields);
          if ((errorKeys.length > 1) && (ix < (errorKeys.length - 1))) str += '\n';
        }
        if (this.fieldErrorsMutable && this.fieldErrorsMutable[field.key]) {
          str += this.fieldErrorsMutable[field.key].join('\n');
        }
        return str;
      }, '');
    },
    getFieldsFromSection(section) {
      return section.groups.reduce((arr, group) => ([...arr, ...group.fields]), []);
    },
    getLabel(id, type = 'groups') {
      const fallback = { label: 'Other' };
      const group = this.formModel[type]
        ? this.formModel[type].find((x) => x.id === id) || fallback
        : fallback;
      return group.label;
    },
    getSectionProps(section) {
      return this.form.sections.length > 1
        ? { // b-card
          header: section.label,
          'header-bg-variant': 'primary',
          'header-text-variant': 'light',
          'no-body': true,
          ...this.sectionProps,
        }
        : {}; // div
    },
    execHandlers(field, fns = []) {
      const done = () => {
        field.submitting = false;
      };
      fns.forEach((fn) => {
        if (typeof fn === 'function') {
          this.$set(field, 'submitting', true);
          fn(field, this.formData, this.formModel, this.onInput, done);
        }
      });
    },
    onFieldAction(field) {
      this.execHandlers(field, [this.actions[field.action], field.onAction]);
    },
    onInput(field, value, meta) {
      this.$v.formData[field.id].$model = value;
      if (this.fieldErrorsMutable && this.fieldErrorsMutable[field.key]) {
        this.$delete(this.fieldErrorsMutable, field.key);
        this.$emit('update:field-errors', this.fieldErrorsMutable);
      }
      if (!meta || (meta && !meta.wasProgrammatic)) this.validateState(field);
      this.execHandlers(field, [this.changeHandlers[field.change_handler], field.onChange]);
    },
    onSubmit() {
      this.submitting = true;
      if (this.currentSectionIndex === (this.form.sections.length - 1)) {
        this.validateFields(this.fields);
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.$emit('error');
          this.$root.$emit(`${this.rootEventPrefix}-error`);
        } else {
          const submittedData = Object.keys(this.formData).reduce((obj, key) => {
            if (!(this.pruneUnentered && [null, undefined].includes(this.formData[key]))) {
              const field = this.fields.find((f) => f.id.toString() === key);
              if (field && (field.show || field.submit_hidden)) obj[key] = this.formData[key];
            }
            return obj;
          }, {});
          this.$emit('submit', submittedData);
          this.$root.$emit(`${this.rootEventPrefix}-submit`, submittedData);
        }
      } else {
        const fields = this.getFieldsFromSection(this.form.sections[this.currentSectionIndex]);
        this.validateFields(fields);
      }
      this.submitting = false;
    },
    scrollToField(field) {
      const el = document.getElementById(`${this.id}-field-${field.id}`);
      if (!isInViewport(el)) {
        scrollToElement(
          el,
          this.scrollToFieldPaddingTop + this.scrollToPaddingTop,
        );
      }
    },
    scrollToSection(section) {
      const el = document.getElementById(section).parentElement.querySelector('.card-header');
      if (!isInViewport(el)) {
        scrollToElement(
          el,
          this.scrollToFieldPaddingTop + this.scrollToPaddingTop,
        );
      }
    },
    showSectionComplete(sectionIndex) {
      return this.isAccordion
        && this.completedSectionComponent
        && this.currentSectionIndex > sectionIndex;
    },
    validateFields(fields) {
      let firstErrorField = null;
      fields.forEach((field) => {
        this.validateState(field, false);
        if (!firstErrorField && this.$v.formData[field.id].$anyError) firstErrorField = field;
      });
      if (firstErrorField !== null) {
        this.scrollToField(firstErrorField);
        return false;
      }
      return true;
    },
    validateState(field, validateDependants = true) {
      let state = null;
      if (field.show || field.validate_hidden) {
        state = !this.$v.formData[field.id].$error;
        let errorMessage = null;
        if (state === false) {
          errorMessage = this.getErrorMessage(field);
          field.show = true;
        }
        this.$set(field, 'errorMessage', errorMessage);
        if (validateDependants && field.dependants) field.dependants.forEach(this.validateState);
      }
      this.$set(field, 'state', state);
    },
  },
  validations() {
    const formData = this.fields.reduce((formObj, field) => {
      const shouldValidate = (field.show || field.validate_hidden) && !!deepGet(field, 'rules.length');
      formObj[field.id] = shouldValidate
        ? field.rules.reduce((rulesObj, ruleString) => {
          const [key] = ruleString.split(':');
          rulesObj[key] = this[`$${this.$cvePrefix}Validations`]
            .getValidation(ruleString, field, this.formData, this.fields);
          return rulesObj;
        }, {})
        : {};
      return formObj;
    }, {});
    if (this.fieldErrorsMutable) {
      Object.keys(this.fieldErrorsMutable).forEach((field, ix) => {
        formData[field][`custom-error-${ix}`] = () => false;
      });
    }
    return { formData };
  },
};
</script>
