<template>
  <component
    :is="tag"
    :class="{'mt-2 mb-1': labelFor}"
    :for="labelFor"
  >
    <markdown
      :markdown="markdown"
      :renderer="markedRenderer()"
    />
    <font-awesome-icon
      v-if="field.help_text"
      v-b-tooltip.hover.right="field.help_text"
      class="text-muted ml-1 cursor-pointer"
      :icon="icons.faQuestionCircle"
    />
  </component>
</template>

<script>
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import marked from 'marked';
import { deepGet } from '../../../utils/object';
import Markdown from './markdown.vue';

export default {
  components: {
    FontAwesomeIcon,
    Markdown,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    labelFor: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: 'label',
    },
  },
  data: () => ({
    icons: { faQuestionCircle },
  }),
  computed: {
    markdown() {
      return `${this.field.label}${this.showAsterisk ? ' <span class="text-danger">*</span>' : ''}`;
    },
    showAsterisk() {
      const override = deepGet(this, 'field.show_asterisk')
        || deepGet(this, 'field.display_attributes.show_asterisk');
      if (typeof override === 'boolean') return override;
      const rules = deepGet(this, 'field.rules');
      return Array.isArray(rules) && rules.some((rule) => ['required', 'confirm'].includes(rule));
    },
  },
  methods: {
    markedRenderer() {
      const renderer = new marked.Renderer();
      renderer.paragraph = function (...args) {
        const paragraph = marked.Renderer.prototype.paragraph.apply(this, args);
        return paragraph.replace('<p', '<span').replace('</p>', '</span>');
      };
      return renderer;
    },
  },
};
</script>
