<template>
  <b-input-group :class="`${fieldClass}--vrn`">
    <template v-slot:prepend>
      <b-input-group-text :class="`${fieldClass}--vrn__prepend`">
        <img :src="require('../../../../assets/img/eu_stars.svg')">
        <span>GB</span>
      </b-input-group-text>
    </template>
    <b-form-input
      :id="id"
      v-model="mutableValue"
      :autofocus="autofocus"
      :disabled="disabled"
      :maxlength="maxLength"
      spellcheck="false"
      :state="state"
      trim
    />
  </b-input-group>
</template>

<script>
import fieldMixin from '../../../../mixins/field';

export default {
  name: 'VrnField',
  mixins: [fieldMixin],
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    maxLength: 8,
  }),
  methods: {
    parseInitialValue(value) {
      if (typeof value === 'string') return value.substring(0, this.maxLength);
      return null;
    },
    transformMutableValue(mutableValue) {
      let val = mutableValue;
      if (!val) return null;
      if (val.length > this.maxLength) val = val.substring(0, this.maxLength);
      return val.toUpperCase();
    },
  },
};
</script>
