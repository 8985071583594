<template>
  <b-form-select
    :id="id"
    v-model="mutableValue"
    :class="`${fieldClass}--select`"
    :disabled="disabled"
    :options="options"
    :state="state"
  />
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { deepGet } from '../../../../utils/object';
import { parseObject } from '../../../../utils/parsers';

export default {
  name: 'SelectField',
  mixins: [fieldMixin],
  computed: {
    options() {
      const options = parseObject(deepGet(this, 'field.options'));
      let selectOptions = [];
      if (Array.isArray(options)) selectOptions = options;
      else selectOptions = Object.keys(options).map((key) => ({ value: key, text: options[key] }));
      return [
        { value: null, text: ' - Select an option - ', disabled: true },
        ...selectOptions,
      ];
    },
  },
};
</script>
