<template>
  <b-form-datepicker
    :id="id"
    v-model="mutableValue"
    v-bind="$attrs"
    :class="`${fieldClass}--date`"
    :disabled="disabled"
    :hide-header="true"
    :label-help="null"
    nav-button-variant="primary"
    show-decade-nav
    :state="state"
    start-weekday="1"
  />
</template>

<script>
import fieldMixin from '../../../../mixins/field';

export default {
  name: 'DateField',
  mixins: [fieldMixin],
};
</script>
