<template>
  <b-form-input
    :id="id"
    v-model="mutableValue"
    :class="`${fieldClass}--numeric`"
    :disabled="disabled"
    :state="state"
    type="number"
  />
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { parseNumber } from '../../../../utils/parsers';

export default {
  name: 'NumericField',
  mixins: [fieldMixin],
  methods: {
    transformMutableValue(mutableValue) {
      return parseNumber(mutableValue, { defaultReturn: null });
    },
  },
};
</script>
