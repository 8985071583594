<template>
  <div :class="`${fieldClass}--slider d-flex`">
    <span v-text="min" />
    <b-form-input
      :id="id"
      v-model="mutableValue"
      class="mx-2"
      :disabled="disabled"
      :max="max"
      :min="min"
      :step="step"
      :state="state"
      type="range"
    />
    <span v-text="max" />
  </div>
</template>

<script>
import fieldMixin from '../../../../mixins/field';
import { parseNumber } from '../../../../utils/parsers';

export default {
  name: 'SliderField',
  mixins: [fieldMixin],
  props: {
    max: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    isInt() {
      return this.step % 1 === 0;
    },
  },
  methods: {
    parseInitialValue(value) {
      return parseNumber(value, { asInt: this.isInt, defaultReturn: this.min });
    },
    transformMutableValue(mutableValue) {
      return parseNumber(mutableValue, { asInt: this.isInt });
    },
  },
};
</script>
